/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PriceApiResponse, PriceApiThunk, PriceState } from "state/types";
import BigNumber from "bignumber.js";

import multicall from "utils/multicall";
import erc20 from "config/abi/erc20.json";
import tokens from "config/constants/tokens";
import { getAddress } from "./../../utils/addressHelpers";

const initialState: PriceState = {
  isLoading: false,
  lastUpdated: null,
  data: {},
};

// Thunks
export const fetchPrices = createAsyncThunk<PriceApiThunk>(
  "prices/fetch",
  async () => {
    //const response2 = await fetch("https://api.pancakeswap.info/api/v2/tokens");    

    const response3 = await fetch("https://api.coingecko.com/api/v3/simple/price?ids=wbnb&vs_currencies=usd");

    const fetchBDSPriceByPairs = async (bnbPrice: string) => {
      const SIG = getAddress(tokens.sig.address);
      const WBNB = getAddress(tokens.wbnb.address);
      const pairs = getAddress(tokens.lpSigWbnb.address); // DADASH - WBNB

      const calls = [
        {
          address: SIG, // DADASH
          name: "balanceOf",
          params: [pairs],
        },
        {
          address: WBNB, // WBNB
          name: "balanceOf",
          params: [pairs], // DADASH - WBNB
        },
      ];
      const [
        sigBal,
        wbnbBal,
      ] = await multicall(erc20, calls);

      const sigPrice = new BigNumber(wbnbBal)
        .div(new BigNumber(10).pow(tokens.wbnb.decimals))
        .div(
          new BigNumber(sigBal).div(new BigNumber(10).pow(tokens.sig.decimals))
        ).multipliedBy(new BigNumber(bnbPrice))
        .toNumber();        

      return {
        sigPrice
      };
    };

    //const data2 = (await response2.json()) as PriceApiResponse;

    const data = { data: null };

    data.data = {
      //...data2.data,
    };

    //const bnbPrice = data.data[getAddress(tokens.wbnb.address)]?.price || "0";
    const data3 = (await response3.json());

    const bnbPrice = data3.wbnb.usd;

    const { sigPrice } =
      await fetchBDSPriceByPairs(bnbPrice);

    // Return normalized token names
    const prices = {
      updated_at: new Date().toString(),
      data: Object.keys(data.data).reduce(
        (accum, token) => {
          return {
            ...accum,
            [token.toLowerCase()]: parseFloat(data.data[token].price),
          };
        },
        {
          [getAddress(tokens.sig.address).toLowerCase()]: sigPrice,
        }
      ),
    };
    
    return prices;
  }
);

export const pricesSlice = createSlice({
  name: "prices",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPrices.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      fetchPrices.fulfilled,
      (state, action: PayloadAction<PriceApiThunk>) => {
        state.isLoading = false;
        state.lastUpdated = action.payload.updated_at;
        state.data = action.payload.data;
      }
    );
  },
});

export default pricesSlice.reducer;
