import React, { useEffect, lazy } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { ResetCSS } from "@bds-libs/uikit";
import BigNumber from "bignumber.js";
//import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";

import { useFetchPriceList } from "state/hooks";
import GlobalStyle from "./style/Global";
import Menu from "./components/Menu";
import SuspenseWithChunkError from "./components/SuspenseWithChunkError";
import ToastListener from "./components/ToastListener";
import PageLoader from "./components/PageLoader";
import history from "./routerHistory";
import Swap from "./views/Swap";
import { RedirectToSwap } from "./views/Swap/redirects";
import {
  RedirectDuplicateTokenIds,
  RedirectOldAddLiquidityPathStructure,
} from "./views/AddLiquidity/redirects";
import RedirectOldRemoveLiquidityPathStructure from "./views/RemoveLiquidity/redirects";

// Route-based code splitting
// Only pool is included in the main bundle because of it's the most visited page
const PoolFinder = lazy(() => import("./views/PoolFinder"));
const NotFound = lazy(() => import("./views/NotFound"));
const Home = lazy(() => import("./views/HomeBDS"));
const AddLiquidity = lazy(() => import("./views/AddLiquidity"));
const RemoveLiquidity = lazy(() => import("./views/RemoveLiquidity"));
const Bond = lazy(() => import("views/Bond"));
const InverseBond = lazy(() => import("views/InverseBond"));
const Staking = lazy(() => import("views/Staking"));
const Shares = lazy(() => import("views/Shares"));
const Liquidity = lazy(() => import("./views/Pool"));

// This config is required for number formating
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
});

//Google Tag Manager
const tagManagerArgs = {
  gtmId: 'GTM-WH6BW78'
}

TagManager.initialize(tagManagerArgs);

history.listen((location) => {
  TagManager.dataLayer({
    event: 'pageview',
    page: {
      url: location.pathname + location.search
    }
  });
});

/*
//GA4 implementation
const TRACKING_ID = "G-52KSWTDFQB";
ReactGA.initialize([{trackingId: TRACKING_ID}]);

history.listen((location) => {
  ReactGA.send({hitType: "pageview", page: location.pathname + location.search});
});
*/

const App: React.FC = () => {
  // Monkey patch warn() because of web3 flood
  // To be removed when web3 1.3.5 is released
  useEffect(() => {
    console.warn = () => null;
  }, []);

  useEffect(() => {
    TagManager.dataLayer({
      event: 'pageview',
      page: {
        url: window.location.pathname + window.location.search
      }
    });
   //ReactGA.send({hitType: "pageview", page: window.location.pathname + window.location.search});
  }, []);

  useFetchPriceList();

  return (
    <Router history={history}>
      <ResetCSS />
      <GlobalStyle />
      <Menu>
        <SuspenseWithChunkError fallback={<PageLoader />}>
          <Switch>
            <Route path="/" exact>
              <Home />
            </Route>
            <Route exact strict path="/swap" component={Swap} />
            <Route path="/swap/:outputCurrency" component={RedirectToSwap} />
            <Route path="/bond">
              <Bond />
            </Route>
            <Route path="/shares">
              <Shares />
            </Route>
            <Route path="/inverse-bond">
              <InverseBond />
            </Route>
            <Route exact path="/add" component={AddLiquidity} />
            <Route exact strict path="/find" component={PoolFinder} />
            <Route
              exact
              path="/add/:currencyIdA"
              component={RedirectOldAddLiquidityPathStructure}
            />
            <Route
              exact
              path="/add/:currencyIdA/:currencyIdB"
              component={RedirectDuplicateTokenIds}
            />
            <Route
              exact
              path="/create/:currencyIdA"
              component={RedirectOldAddLiquidityPathStructure}
            />
            <Route
              exact
              path="/create/:currencyIdA/:currencyIdB"
              component={RedirectDuplicateTokenIds}
            />
            <Route
              exact
              strict
              path="/remove/:tokens"
              component={RedirectOldRemoveLiquidityPathStructure}
            />
            <Route
              exact
              strict
              path="/remove/:currencyIdA/:currencyIdB"
              component={RemoveLiquidity}
            />
            <Route path="/pool">
              <Redirect to="/liquidity" />
            </Route>
            <Route exact strict path="/staking" component={Staking} />
            <Route exact strict path="/liquidity" component={Liquidity} />
            {/* 404 */}
            <Route component={NotFound} />
          </Switch>
        </SuspenseWithChunkError>
      </Menu>
      <ToastListener />
    </Router>
  );
};

export default React.memo(App);
