import { MenuEntry } from "@bds-libs/uikit";

const config = (t: (r: string) => string) => {
  const result: MenuEntry[] = [
    {
      label: t("Dashboard"),
      icon: "DashboardIcon",
      showOnMobile: true,
      href: "/",
    },
    {
      label: t("Exchange"),
      icon: "NewTradeIcon",
      showOnMobile: true,
      href: "/swap",
    },
    {
      label: t("Staking 1.5X"),
      icon: "StakeIcon",
      showOnMobile: true,
      href: "/staking",
    },
    {
      label: t("Shares"),
      icon: "SharesIcon",
      showOnMobile: true,
      href: "/shares",
    },
    {
      label: t("Documentation"),
      icon: "DocumentIcon",
      showOnMobile: true,
      href: "https://docs.dashdashdash.org",
    },
  ];

  return result;
};

export default config;
